import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
  Card,
  CardImg
} from "reactstrap"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AvForm, AvField } from "availity-reactstrap-validation"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/danawa/bg_landing.jpg"
import sronLogo from "assets/images/danawa/sron-logo.png"

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import bannerBlank from "assets/images/danawa/banner.png"

import bgBlue from "assets/images/danawa/bg.svg"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const DanawaLanding = props => {
  const history = useHistory()
  const query = useQuery()

  const [languageSelected, setlanguageSelected] = useState('eng')

  const toggleLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setlanguageSelected(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
  }

  const [bannerData, setbannerData] = useState([])
  const [currentBanner, setcurrentBanner] = useState(0)
  const getBanners = async () => {
    // var form = JSON.stringify({
    //   option: "list_package",
    //   type: type,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_banner")
    // urlencoded.append("type", type)
    // urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request banner", urlencoded)

    const response = await fetch(url.DANAWA_POST_FRONTEND, requestOptions)
    const postresponse = await response.json()

    console.log("list_banner", postresponse)

    if (response.status == 200 && postresponse.success) {
      setbannerData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      history.push("/dashboard")
    } else {
      getBanners()
    }

    if (localStorage.getItem("I18N_LANGUAGE")) {
      i18n.changeLanguage(localStorage.getItem("I18N_LANGUAGE"))
      setlanguageSelected(localStorage.getItem("I18N_LANGUAGE"))
    } else {
      console.log("not stored")
      localStorage.setItem("I18N_LANGUAGE", "eng")
      i18n.changeLanguage("eng")
      setlanguageSelected("eng")
    }
  }, [])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* <CarouselPage /> */}
            <Col
              // xl={6}
              className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "#27417F",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                // backgroundRepeat: "no-repeat",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content mx-auto"
                style={{ minHeight: "100vh" }}
              >
                <div className="w-100">
                  {/* <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url(${bgBlue})`,
                      backgroundSize: "contain",
                    }}
                  /> */}
                  <div className="d-flex flex-column h-100">
                    <Row className="justify-content-center">
                      <Col xl={7} lg={10}>
                        <Row
                          className="mb-5"
                          // style={{ maxWidth: "720px" }}
                        >
                          <Col>
                            <Carousel
                              infiniteLoop
                              autoPlay
                              swipeable
                              emulateTouch
                              interval={3000}
                              showThumbs={false}
                              className="slider_css"
                              dir="rtl"
                              onChange={index => {
                                setcurrentBanner(index)
                              }}
                              onClickItem={e => {
                                // bannerData &&
                                //   onClickBanner(
                                //     bannerData[e].linkage_type,
                                //     bannerData[e].linkage_detail
                                //   )
                              }}
                            >
                              {bannerData &&
                                bannerData.map((banner, index) => (
                                  <div key={"banner-" + index}>
                                    <div className="item">
                                      <Card className="text-center pt-0 shadow-none bg-transparent">
                                        <CardImg
                                          top
                                          className="img-fluid"
                                          style={{
                                            // height: "280px",
                                            objectFit: "cover",
                                            // borderRadius: "14px",
                                            aspectRatio: "4/3",
                                          }}
                                          src={banner.image}
                                          alt=""
                                          onError={e => {
                                            onImageError(e, bannerBlank)
                                          }}
                                        />
                                      </Card>
                                    </div>
                                  </div>
                                ))}
                            </Carousel>
                          </Col>
                          <span
                            className="text-white font-size-14 px-4"
                            style={{ marginTop: "36px" }}
                          >
                            {bannerData.length > 0 &&
                              bannerData[currentBanner].caption}
                          </span>
                          <img
                            src={sronLogo}
                            alt=""
                            style={{
                              position: "absolute",
                              top: "16px",
                              left: "32px",
                              width: "150px",
                              objectFit: "contain",
                              maxHeight: "40px",
                              // backgroundColor: "#EBEBEB",
                              zIndex: 1003,
                            }}
                          />
                          <button
                            className={
                              "btn btn-primary btn-block waves-effect waves-light font-size-16"
                            }
                            style={{
                              position: "absolute",
                              top: "16px",
                              right: "32px",
                              maxWidth: "150px",
                              maxHeight: "40px",
                              zIndex: 1003,
                              borderRadius: "8px",
                            }}
                            onClick={
                              () => { if (languageSelected == "eng") { toggleLanguage("bm") } else toggleLanguage("eng") }
                            }
                          >
                            <strong>{languageSelected == "eng" ? props.t("BM") : props.t("ENG")}</strong>
                          </button>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      className="mt-auto justify-content-center"
                      style={{
                        backgroundColor: "#27417F",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                      }}
                    >
                      <Col xl={7} lg={10}>
                        <Row className="mb-3 px-3">
                          <Col>
                            <Link to="/login">
                              <button
                                className={
                                  "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                                }
                                style={{
                                  borderRadius: "8px",
                                }}
                                // onClick={submitLogin}
                              >
                                <strong>{props.t("Sign In")}</strong>
                              </button>
                            </Link>
                          </Col>
                        </Row>
                        <Row
                          className="mb-3 px-3"
                          // style={{ maxWidth: "720px" }}
                        >
                          <Col>
                            <Link to="/sign-up">
                              <button
                                className={
                                  "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                                }
                                style={{
                                  borderRadius: "8px",
                                  backgroundColor: "#AF3B30",
                                }}
                                // onClick={submitLogin}
                              >
                                <strong>{props.t("SIGN UP TO BEGIN")}</strong>
                              </button>
                            </Link>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaLanding)

DanawaLanding.propTypes = {
t: PropTypes.any,
}
